/**
 * @generated SignedSource<<f3c3aa337dfd2f8fca1cd4ef18ba3440>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_ReferenceAnnotationPageQuery$variables = {
  referenceAnnotationHandle: string;
};
export type References_ReferenceAnnotationPageQuery$data = {
  readonly referenceAnnotation: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferenceAnnotationPage_referenceAnnotation">;
  } | null;
};
export type References_ReferenceAnnotationPageQuery = {
  response: References_ReferenceAnnotationPageQuery$data;
  variables: References_ReferenceAnnotationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceAnnotationHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceAnnotationHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_ReferenceAnnotationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferenceAnnotation",
        "kind": "LinkedField",
        "name": "referenceAnnotation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferenceAnnotationPage_referenceAnnotation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_ReferenceAnnotationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferenceAnnotation",
        "kind": "LinkedField",
        "name": "referenceAnnotation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileUrl",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUser",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "userProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Reference",
            "kind": "LinkedField",
            "name": "reference",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "048a7d230f52a495f27ed98744cfcf9c",
    "id": null,
    "metadata": {},
    "name": "References_ReferenceAnnotationPageQuery",
    "operationKind": "query",
    "text": "query References_ReferenceAnnotationPageQuery(\n  $referenceAnnotationHandle: String!\n) {\n  referenceAnnotation(handle: $referenceAnnotationHandle) {\n    ...ReferenceAnnotationPage_referenceAnnotation\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByKeyValue_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment DeleteReferenceAnnotationControl_referenceAnnotation on ReferenceAnnotation {\n  id\n  reference {\n    id\n    handle\n  }\n}\n\nfragment EditReferenceAnnotationControl_referenceAnnotation on ReferenceAnnotation {\n  reference {\n    handle\n    tenant {\n      id\n    }\n    id\n  }\n  handle\n}\n\nfragment ReferenceAnnotationPageHeader_referenceAnnotation on ReferenceAnnotation {\n  handle\n  name\n  reference {\n    handle\n    name\n    id\n  }\n  ...EditReferenceAnnotationControl_referenceAnnotation\n  ...DeleteReferenceAnnotationControl_referenceAnnotation\n}\n\nfragment ReferenceAnnotationPage_referenceAnnotation on ReferenceAnnotation {\n  description\n  fileUrl\n  name\n  status\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByKeyValue_userProfile\n      id\n    }\n    id\n  }\n  reference {\n    handle\n    name\n    id\n  }\n  ...ReferenceAnnotationPageHeader_referenceAnnotation\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "40a08d81239a195f75db4d506ee66ddd";

export default node;
