import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <Route
    path="metadata"
    getComponent={() =>
      import(
        /* webpackChunkName: "metadata" */ './components/MetadataPage'
      ).then((p) => p.default)
    }
    query={graphql`
      query MetadataPageQuery($tenantSlug: String!) {
        tenant: tenantBySlug(slug: $tenantSlug) {
          ...MetadataPage_tenant
        }
      }
    `}
  />
);
