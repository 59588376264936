/**
 * @generated SignedSource<<86cfe04b0e09da80f62bc7ef0653be98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetadataPageQuery$variables = {
  tenantSlug: string;
};
export type MetadataPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"MetadataPage_tenant">;
  } | null;
};
export type MetadataPageQuery = {
  response: MetadataPageQuery$data;
  variables: MetadataPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataPageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MetadataPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataPageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RunCustomFieldSet",
            "kind": "LinkedField",
            "name": "latestRunCustomFieldSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schema",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2529757eb2c309a5b584e26e41934672",
    "id": null,
    "metadata": {},
    "name": "MetadataPageQuery",
    "operationKind": "query",
    "text": "query MetadataPageQuery(\n  $tenantSlug: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...MetadataPage_tenant\n    id\n  }\n}\n\nfragment MetadataPage_tenant on Tenant {\n  latestRunCustomFieldSet {\n    schema\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c976091bdd34b6c3fcc081659afb9bae";

export default node;
