import classNames from 'classnames';
import { useRouter } from 'found';
import React from 'react';
import { RiNotificationFill as Bell } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import SrOnly from 'components/SrOnly';
import Text from 'components/Text';
import { getNodes, insertNode } from 'utils/graphql';
import { useTenantRoutes } from 'utils/tenantRoutes';

import Button from './Button';
import Dropdown, { DropdownMenuItem, DropdownMenuSeparator } from './Dropdown';
import Icon from './Icon';
import type { NotificationsMenuSubscription } from './__generated__/NotificationsMenuSubscription.graphql';
import type { NotificationsMenu_tenant$data as Tenant } from './__generated__/NotificationsMenu_tenant.graphql';
import useNotificationData, {
  useNotificationDataProps,
} from './useNotificationData';

import './NotificationsMenu.scss';

interface Props {
  tenant: DeepNonNull<Tenant>;
  dataTestId?: string;
}

const rootClass = 'NotificationsMenu';

const subscription = graphql`
  subscription NotificationsMenuSubscription(
    $input: CreateNotificationSubscriptionInput!
  ) {
    createNotification(input: $input) {
      notificationEdge {
        node {
          ... on Node {
            id
          }
          ...useNotificationData_notification
        }
      }
    }
  }
`;

function NotificationListItem(props: useNotificationDataProps) {
  const data = useNotificationData(props);
  return (
    <DropdownMenuItem onClick={data.route}>{data.message}</DropdownMenuItem>
  );
}

function NotificationsMenu({ tenant, dataTestId = rootClass }: Props) {
  const { router } = useRouter();
  const routes = useTenantRoutes();

  const input = {
    tenantId: tenant.id,
    userId: tenant.currentUser.userProfile.id,
  };
  useSubscription<NotificationsMenuSubscription>({
    subscription,
    variables: {
      input,
    },
    updater: (store) => {
      const connectionFilters = { hideRead: true };
      const numNotifications = store
        .get<{ numNotifications: number }>(tenant.currentUser.id)!
        .getValue('numNotifications', connectionFilters);
      store
        .get(tenant.currentUser.id)!
        .setValue(numNotifications + 1, 'numNotifications', connectionFilters);
      insertNode(store, {
        parentId: tenant.currentUser.id,
        connectionKey: 'TenantUser_notificationConnection',
        rootFieldName: 'createNotification',
        edgeName: 'notificationEdge',
        rangeBehavior: 'prepend',
        connectionFilters,
      });
    },
  });

  const { numNotifications, notificationConnection } = tenant.currentUser;

  return (
    <Dropdown
      dataTestId={dataTestId}
      trigger={
        <Button variant="navbar">
          <Icon>
            <Bell />
          </Icon>
          <SrOnly>
            <FormattedMessage
              id="notificationsMenu.accessibilityTitle"
              defaultMessage="Notifications"
            />
          </SrOnly>
          {numNotifications > 0 && (
            <Text
              size="xs"
              color="headline"
              data-testid={`${dataTestId}Bubble`}
              className={classNames(`${rootClass}__notificationBubble`)}
            >
              {numNotifications}
            </Text>
          )}
        </Button>
      }
    >
      {getNodes(notificationConnection).map((notification) => (
        <NotificationListItem
          notification={notification}
          tenant={tenant}
          key={notification.id}
        />
      ))}

      {numNotifications > 0 && <DropdownMenuSeparator />}
      <DropdownMenuItem
        key="VIEW_ALL"
        onClick={() => router.push(routes.notifications())}
        data-testid={`${dataTestId}-viewAll`}
      >
        <FormattedMessage
          id="notificationsMenu.viewAll"
          defaultMessage="View All"
        />
      </DropdownMenuItem>
    </Dropdown>
  );
}

export default createFragmentContainer(NotificationsMenu, {
  tenant: graphql`
    fragment NotificationsMenu_tenant on Tenant {
      ...useNotificationData_tenant
      id
      currentUser {
        id
        userProfile {
          id
        }
        numNotifications(hideRead: true)
        notificationConnection(first: 5, hideRead: true)
          @connection(key: "TenantUser_notificationConnection") {
          edges {
            node {
              ... on Node {
                id
              }
              ...useNotificationData_notification
            }
          }
        }
      }
    }
  `,
});
