import type { Match } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import {
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import applyFunctions from 'utils/applyFunctions';
import { createCheckItem } from 'utils/checkItem';

import getReferenceSearchVariables from './utils/getReferenceSearchVariables';

export default (
  <Route path="references">
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "references" */ './components/ReferencesPage'
        ).then((p) => p.default)
      }
      query={graphql`
        query References_ReferencesPageQuery(
          $count: Int!
          $cursor: String
          $tenantSlug: String!
          $sort: [ReferenceSorting!]
          $proteomeFileStatus: [ReferenceProteomeFileStatus!]
          $search: String
          $userProfileIdSearchFilters: [IdOrHandle!]
          $selectedUserProfileHandle: String
          $hasUserProfile: Boolean!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...ReferencesPage_tenant
          }
          userProfile(handle: $selectedUserProfileHandle)
            @include(if: $hasUserProfile) {
            ...ReferencesPage_userProfile
          }
        }
      `}
      prepareVariables={applyFunctions<[Obj, Match]>(
        getInfiniteListVariables,
        getSortVariables,
        getReferenceSearchVariables,
      )}
    />
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "references" */ './components/NewReferencePage'
        ).then((p) => p.default)
      }
      query={graphql`
        query References_NewReferencePageQuery($tenantSlug: String!) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...NewReferencePage_tenant
          }
        }
      `}
    />
    <Route path=":referenceHandle">
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "references" */ './components/ReferencePage'
          ).then((p) => p.default)
        }
        query={graphql`
          query References_ReferencePageQuery($referenceHandle: String!) {
            reference(handle: $referenceHandle) {
              ...ReferencePage_reference
            }
          }
        `}
        prerender={createCheckItem('reference')}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "references" */ './components/ReferenceSummarySection'
            ).then((p) => p.default)
          }
          query={graphql`
            query References_ReferenceSummarySectionQuery(
              $referenceHandle: String!
            ) {
              reference(handle: $referenceHandle) {
                ...ReferenceSummarySection_reference
              }
            }
          `}
        />
        <Route
          path="annotations"
          getComponent={() =>
            import(
              /* webpackChunkName: "references" */ './components/ReferenceAnnotationsListSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query References_ReferenceAnnotationsListSectionQuery(
              $referenceHandle: String!
              $count: Int!
              $cursor: String
            ) {
              reference(handle: $referenceHandle) {
                ...ReferenceAnnotationsListSection_reference
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "references" */ './components/EditReferencePage'
          ).then((p) => p.default)
        }
        query={graphql`
          query References_EditReferencePageQuery($referenceHandle: String!) {
            reference(handle: $referenceHandle) {
              ...EditReferencePage_reference
            }
          }
        `}
      />
      <Route
        path="annotations/-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "references" */ './components/NewReferenceAnnotationPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query References_NewReferenceAnnotationPageQuery(
            $referenceHandle: String!
          ) {
            reference(handle: $referenceHandle) {
              ...NewReferenceAnnotationPage_reference
            }
          }
        `}
      />
      <Route
        path="annotations/:referenceAnnotationHandle"
        query={graphql`
          query References_ReferenceAnnotationQuery(
            $referenceAnnotationHandle: String!
          ) {
            referenceAnnotation(handle: $referenceAnnotationHandle) {
              __typename
            }
          }
        `}
        prerender={createCheckItem('referenceAnnotation')}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "references" */ './components/ReferenceAnnotationPage'
            ).then((p) => p.default)
          }
          query={graphql`
            query References_ReferenceAnnotationPageQuery(
              $referenceAnnotationHandle: String!
            ) {
              referenceAnnotation(handle: $referenceAnnotationHandle) {
                ...ReferenceAnnotationPage_referenceAnnotation
              }
            }
          `}
        />
        <Route
          path="edit"
          getComponent={() =>
            import(
              /* webpackChunkName: "references" */ './components/EditReferenceAnnotationPage'
            ).then((p) => p.default)
          }
          query={graphql`
            query References_EditReferenceAnnotationPageQuery(
              $referenceAnnotationHandle: String!
            ) {
              referenceAnnotation(handle: $referenceAnnotationHandle) {
                ...EditReferenceAnnotationPage_referenceAnnotation
              }
            }
          `}
        />
      </Route>
    </Route>
  </Route>
);
