/**
 * @generated SignedSource<<2cefd6f980e7d8128fdc13bd00c9f815>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_EditReferencePageQuery$variables = {
  referenceHandle: string;
};
export type References_EditReferencePageQuery$data = {
  readonly reference: {
    readonly " $fragmentSpreads": FragmentRefs<"EditReferencePage_reference">;
  } | null;
};
export type References_EditReferencePageQuery = {
  response: References_EditReferencePageQuery$data;
  variables: References_EditReferencePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_EditReferencePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditReferencePage_reference"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_EditReferencePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proteomeFilename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proteomeFileStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2e93d21478c110f80c53926f683872a",
    "id": null,
    "metadata": {},
    "name": "References_EditReferencePageQuery",
    "operationKind": "query",
    "text": "query References_EditReferencePageQuery(\n  $referenceHandle: String!\n) {\n  reference(handle: $referenceHandle) {\n    ...EditReferencePage_reference\n    id\n  }\n}\n\nfragment EditReferencePage_reference on Reference {\n  id\n  handle\n  tenant {\n    slug\n    id\n  }\n  name\n  description\n  proteomeFilename\n  proteomeFileStatus\n}\n"
  }
};
})();

(node as any).hash = "c7891abbb3a1d87516be9161611c6197";

export default node;
