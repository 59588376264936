/**
 * @generated SignedSource<<495b229413ae53c3a0bbacf827216d26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsMenu_tenant$data = {
  readonly currentUser: {
    readonly id: string;
    readonly notificationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id?: string;
          readonly " $fragmentSpreads": FragmentRefs<"useNotificationData_notification">;
        } | null;
      } | null> | null;
    } | null;
    readonly numNotifications: number | null;
    readonly userProfile: {
      readonly id: string;
    } | null;
  } | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationData_tenant">;
  readonly " $fragmentType": "NotificationsMenu_tenant";
};
export type NotificationsMenu_tenant$key = {
  readonly " $data"?: NotificationsMenu_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationsMenu_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = [
  {
    "kind": "Literal",
    "name": "hideRead",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "currentUser",
          "notificationConnection"
        ]
      }
    ]
  },
  "name": "NotificationsMenu_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useNotificationData_tenant"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantUser",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "userProfile",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "numNotifications",
          "storageKey": "numNotifications(hideRead:true)"
        },
        {
          "alias": "notificationConnection",
          "args": (v2/*: any*/),
          "concreteType": "NotificationConnection",
          "kind": "LinkedField",
          "name": "__TenantUser_notificationConnection_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NotificationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": (v1/*: any*/),
                      "type": "Node",
                      "abstractKey": "__isNode"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "useNotificationData_notification"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "__TenantUser_notificationConnection_connection(hideRead:true)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
})();

(node as any).hash = "04488ca4ef96f01c49bc510337df5cd5";

export default node;
