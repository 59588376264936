/**
 * @generated SignedSource<<67c23556939d63dfa02c52c9df2c3bc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReferenceProteomeFileStatus = "MISSING" | "PREPARING" | "READY" | "UPLOADING" | "VALIDATING" | "VALIDATING_FAILED";
export type ReferenceSorting = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "NAME_ASC" | "NAME_DESC" | "NAME_LENGTH_ASC" | "NAME_LENGTH_DESC";
export type References_ReferencesPageQuery$variables = {
  count: number;
  cursor?: string | null;
  hasUserProfile: boolean;
  proteomeFileStatus?: ReadonlyArray<ReferenceProteomeFileStatus> | null;
  search?: string | null;
  selectedUserProfileHandle?: string | null;
  sort?: ReadonlyArray<ReferenceSorting> | null;
  tenantSlug: string;
  userProfileIdSearchFilters?: ReadonlyArray<any> | null;
};
export type References_ReferencesPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferencesPage_tenant">;
  } | null;
  readonly userProfile?: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferencesPage_userProfile">;
  } | null;
};
export type References_ReferencesPageQuery = {
  response: References_ReferencesPageQuery$data;
  variables: References_ReferencesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserProfile"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "proteomeFileStatus"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedUserProfileHandle"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userProfileIdSearchFilters"
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "selectedUserProfileHandle"
  }
],
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "createdBy",
    "variableName": "userProfileIdSearchFilters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "proteomeFileStatus",
    "variableName": "proteomeFileStatus"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pictureUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "References_ReferencesPageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v9/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferencesPage_tenant"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasUserProfile",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ReferencesPage_userProfile"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "References_ReferencesPageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v9/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": "ReferenceConnection",
            "kind": "LinkedField",
            "name": "referenceConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReferenceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Reference",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "proteomeFileStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "filters": [
              "sort",
              "search",
              "proteomeFileStatus",
              "createdBy"
            ],
            "handle": "connection",
            "key": "Tenant_referenceConnection",
            "kind": "LinkedHandle",
            "name": "referenceConnection"
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasUserProfile",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "74f49c55393a91587a7cea4fa0b8440e",
    "id": null,
    "metadata": {},
    "name": "References_ReferencesPageQuery",
    "operationKind": "query",
    "text": "query References_ReferencesPageQuery(\n  $count: Int!\n  $cursor: String\n  $tenantSlug: String!\n  $sort: [ReferenceSorting!]\n  $proteomeFileStatus: [ReferenceProteomeFileStatus!]\n  $search: String\n  $userProfileIdSearchFilters: [IdOrHandle!]\n  $selectedUserProfileHandle: String\n  $hasUserProfile: Boolean!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...ReferencesPage_tenant\n    id\n  }\n  userProfile(handle: $selectedUserProfileHandle) @include(if: $hasUserProfile) {\n    ...ReferencesPage_userProfile\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment ReferenceListActionBar_userProfile on UserProfile {\n  ...ReferenceSearchBar_userProfile\n}\n\nfragment ReferenceList_references on Reference {\n  id\n  handle\n  name\n  proteomeFileStatus\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n}\n\nfragment ReferenceSearchBar_userProfile on UserProfile {\n  handle\n  displayName\n  pictureUrl\n}\n\nfragment ReferencesPage_tenant on Tenant {\n  referenceConnection(first: $count, after: $cursor, sort: $sort, search: $search, proteomeFileStatus: $proteomeFileStatus, createdBy: $userProfileIdSearchFilters) {\n    edges {\n      node {\n        ...ReferenceList_references\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ReferencesPage_userProfile on UserProfile {\n  ...ReferenceListActionBar_userProfile\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "1ba6ed3f7f85ef4bbef6660a72051420";

export default node;
