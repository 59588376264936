import React from 'react';

import Route from 'components/Route';

import metadataRoute from './routes/Metadata';
import protocolsRoute from './routes/Protocols';
import tagsRoute from './routes/Tags';
import tenantRoute from './routes/Tenant';
import usersRoute from './routes/Users';

export default (
  <Route>
    {protocolsRoute}
    {tagsRoute}
    {tenantRoute}
    {metadataRoute}
    {usersRoute}
  </Route>
);
