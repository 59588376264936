/**
 * @generated SignedSource<<7b4c6117e3b4188338d572326764582c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Analyses_plotsTabsQuery$variables = {
  analysisHandle: string;
};
export type Analyses_plotsTabsQuery$data = {
  readonly analysis: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisPlotsTabs_analysis">;
  } | null;
};
export type Analyses_plotsTabsQuery = {
  response: Analyses_plotsTabsQuery$data;
  variables: Analyses_plotsTabsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_plotsTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisPlotsTabs_analysis"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_plotsTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisReport",
                "kind": "LinkedField",
                "name": "report",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisImage",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grouping",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c142f274e322c96646ab5b65cd8776c1",
    "id": null,
    "metadata": {},
    "name": "Analyses_plotsTabsQuery",
    "operationKind": "query",
    "text": "query Analyses_plotsTabsQuery(\n  $analysisHandle: String!\n) {\n  analysis(handle: $analysisHandle) {\n    ...AnalysisPlotsTabs_analysis\n    id\n  }\n}\n\nfragment AnalysisPlotsTabs_analysis on Analysis {\n  handle\n  data {\n    report {\n      images {\n        grouping\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "07efd68b0ff6f7fe445b54f7e3fabca2";

export default node;
