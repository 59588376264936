/**
 * @generated SignedSource<<68ecf57a22ff59b099a2a14f3eddd0b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Analyses_filesSectionQuery$variables = {
  analysisHandle: string;
};
export type Analyses_filesSectionQuery$data = {
  readonly analysis: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisFilesSection_analysis">;
  } | null;
};
export type Analyses_filesSectionQuery = {
  response: Analyses_filesSectionQuery$data;
  variables: Analyses_filesSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_filesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisFilesSection_analysis"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_filesSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisReport",
                "kind": "LinkedField",
                "name": "report",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisFile",
                    "kind": "LinkedField",
                    "name": "files",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileSize",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grouping",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showTabbed",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb014487866e143d8c407e38e27bcae6",
    "id": null,
    "metadata": {},
    "name": "Analyses_filesSectionQuery",
    "operationKind": "query",
    "text": "query Analyses_filesSectionQuery(\n  $analysisHandle: String!\n) {\n  analysis(handle: $analysisHandle) {\n    ...AnalysisFilesSection_analysis\n    id\n  }\n}\n\nfragment AnalysisFilesSection_analysis on Analysis {\n  handle\n  data {\n    report {\n      files {\n        fileSize\n        handle\n        url\n        title\n        grouping\n        showTabbed\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "74c337137b93be371941bf2063bd8329";

export default node;
