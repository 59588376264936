/**
 * @generated SignedSource<<2ad1e71df08439ef2eda87cf425f072a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_ReferenceSummarySectionQuery$variables = {
  referenceHandle: string;
};
export type References_ReferenceSummarySectionQuery$data = {
  readonly reference: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferenceSummarySection_reference">;
  } | null;
};
export type References_ReferenceSummarySectionQuery = {
  response: References_ReferenceSummarySectionQuery$data;
  variables: References_ReferenceSummarySectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_ReferenceSummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferenceSummarySection_reference"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_ReferenceSummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proteomeFilename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proteomeFileStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proteomeFileUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUser",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "userProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd31be10b7ab46110a902e4ca19854c2",
    "id": null,
    "metadata": {},
    "name": "References_ReferenceSummarySectionQuery",
    "operationKind": "query",
    "text": "query References_ReferenceSummarySectionQuery(\n  $referenceHandle: String!\n) {\n  reference(handle: $referenceHandle) {\n    ...ReferenceSummarySection_reference\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByKeyValue_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment ReferenceSummarySection_reference on Reference {\n  id\n  description\n  createdAt\n  proteomeFilename\n  proteomeFileStatus\n  proteomeFileUrl\n  createdBy {\n    userProfile {\n      ...CreatedByKeyValue_userProfile\n      id\n    }\n    id\n  }\n  tenant {\n    id\n  }\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "e9ae82858d621ab99a5fae49ad541ec4";

export default node;
